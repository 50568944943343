import styled from '@emotion/styled';

import { getStyleSizes } from '@styles/styles.utils';
import {
  color_light_grey,
  color_lightest_grey,
  color_white,
} from '@styles/colors';

import {
  NavPanelStyledProps,
} from './nav-panel.interface';
import { mq_min, mq_max } from '@styles/breakpoints';

export const DropdownContent = styled.div<NavPanelStyledProps>`
  ${({ state }) => `display: ${state === 'open' ? 'flex' : 'none'};`};
  position: fixed;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 55px);
  background-color: ${color_white};
  background: ${color_white};
  width: 100%;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.35);
  border-top: 1px solid rgba(196, 199, 205, 0.50);
  min-height: 506px;
  padding: 0 calc((100% - 1332px) / 2);
  ${mq_max('lg')}{
    position: relative;
    transform: none;
    box-shadow: none;
    top: 0;
    left: 0;
    flex-wrap: wrap;
    border-top: none;
    min-height: 0px;
  }
`;

export const ItemsSection = styled.div`
  width: 67.3%;
  padding: 32px 48px 40px 45px;
  ${mq_max('lg')}{
    width: 100%;
    border-top: none;
    padding: 24px 20px 13px;
  }
  ${mq_max('md')}{
    padding: 27px 20px 32px;
  }
`
export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  ${mq_max('lg')}{
    gap: ${getStyleSizes(31)};
    max-width: 100%;
  }
  ${mq_max('md')}{
    flex-wrap: wrap;
    gap: 0px;
  }
`

export const SectionCol = styled.div`
  width: calc(25% - 10px);
  max-width: 180px;
  min-height: 230px;
  ${mq_max('lg')}{
    min-height: 0px;
  }
 ${mq_max('md')}{
    width: 100%;
    max-width: calc(50% - 16px) !important;
    width: 50% !important;
  }
   &:has(.pricing){
    display: flex;
    flex-direction: column;
  }
`

export const ColItems = styled.div`
  display: flex;
  flex-direction: column;

  &:has(.pricing){
    flex: 1;
  }
`;

export const LinkStyled = styled.span`
  margin-bottom: 17px;
  display: block;
  width: fit-content;
  > *{
    width: auto !important;
    display: block;
    cursor: pointer;
  }
  &:hover{
    > span:first-of-type,
    > a span:first-of-type{
      color: #3ACDEF;
    }
  }
  &:active{
    > span:first-of-type,
    > a span:first-of-type{
      color: #2294AF;
    }
  }
  &.pricing{
    border-bottom: 1px solid  rgba(133, 137, 140, 0.40);
    padding-bottom: 12px;
    display: block;
    width: 100%;
    margin-top: 16px;
    ${mq_min('md')} {
      margin-top: auto;
    }
    ${mq_max('md')} {
      margin-bottom: 0;
    }
    > *{
      display: block;
    }
    > span:first-of-type{
      color: #119FC0;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0.26px;
      text-transform: uppercase;

      &:hover{
        color: #5DDAFB;

        svg{
          path{
            fill: #5DDAFB;
          }
        }
      }
    }
    &:active{
      > span:first-of-type{
        color: #25C9EF;
        svg{
          path{
            fill: #25C9EF;
          }
        }
      }
    }
  }
`;
export const ItemTitle = styled.span<{isContact?: boolean}>`
  color: #1A1A1A;
  font-size: ${getStyleSizes(14)};
  font-style: normal;
  font-weight: ${({ isContact }) => `${isContact ? '800' : '600'};`};;
  line-height: 20px;
  display: block;
  svg{
    margin-left: 8px;
  }
`;
export const ItemDescription = styled.span`
  color: #85898C;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  display: block;
`;

export const SectionTitleStyled = styled.span`
  color: #119FC0;
  font-size: ${getStyleSizes(13)};
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.26px;
  border-bottom: 1px solid rgba(133, 137, 140, 0.40);
  padding-bottom: 8px;
  margin-bottom: 19px;
  display: block;
  max-width: 362px;
  white-space: nowrap;
  ${mq_max('lg')} {
    max-width: 100%;
  }
`
export const FeaturedSection = styled.div`
  width: 32.7%;
  padding: 32px 24px 40px;
  border-left: 1px solid rgba(196, 199, 205, 0.50);
  background: #F5F5F5;
  position: relative;
   ${mq_max('lg')} {
    padding: 30px 20px 40px;
    border-top: 1px solid rgba(196, 199, 205, 0.50);
    border-bottom: 1px solid rgba(196, 199, 205, 0.50);
    width: 100%;
  }
   ${mq_max('md')} {
    padding: 32px 20px 48px;
    border-bottom: 1px solid rgba(196, 199, 205, 0.50);
  }
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: #F5F5F5;
    display: block;
    transform: translateX(100%);
  }
  > span:first-of-type{
       ${mq_max('lg')} {
        max-width: 100%;
      }
  }
`

export const FeaturedRow = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 362px;
  padding-top: 2px;
   ${mq_max('lg')} {
    max-width: 100%;
  }
`;
export const FeaturedCol = styled.div`
  width: calc(50% - 7px);
   ${mq_max('lg')} {
    display: flex;
  }
   ${mq_max('md')} {
    flex-direction: column;
  }
`

export const FeaturedContent = styled.div`
   ${mq_max('lg')} {
    padding-left: 16px;
  }
   ${mq_max('md')} {
    padding-left: 0px;
    padding-top: 16px;
  }
`
export const FeaturedSubHeadline = styled.div`
  color: #119FC0;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  display: block;
  margin-top: 16px;
  ${mq_max('lg')}{
    margin-top: 0px;
  }
`
export const FeaturedTitle = styled.div`
  color: #1A1A1A;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  display: block;
  margin: 8px 0px;
   ${mq_max('lg')} {
    max-width: 200px;
  }
   ${mq_max('md')} {
    max-width: 100%;
    text-underline-offset: 2px;
  }
  &:hover,
  &:active{
    text-decoration: none;
  }
`
export const FeaturedDate = styled.div`
  color: #85898C;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
  display: block;
`

export const FeaturedImageDesktop = styled.div`
  display: none;
  ${mq_min('lg')} {
    display: block;
  }
  img{
      width: 100%;
      object-fit: cover;
  }
  .gatsby-image-wrapper{
    width: 100%;
    min-height: 225px;
  }
`
export const FeaturedImageTablet = styled.div`
  display: block;
  ${mq_min('lg')} {
    display: none;
  }
  img{
    width: 100%;
    object-fit: cover;
  }
  .gatsby-image-wrapper{
    width: 100%;
    min-height: 225px;
    ${mq_max('lg')} {
      width: 120px;
      min-height: 120px;
    }
    ${mq_max('md')}{
      width: 153px;
      height: 120px;
    }
  }
`

export const CTAMobileRow = styled.div`
  width: 100%;
  text-align: center;
  display: none;
  ${mq_max('md')} {
    display: block;
    margin-top: 24px;
  }

  button{
    width: 100%;
    margin-bottom: 8px;
  }
`
